import React from 'react';
import HeroSection from '../../components/Blocks/HeroSection/heroSection';
import Paragraph from '../../components/Blocks/Paragraph/paragraph';
import Section from '../../components/Blocks/Section/section';
import Layout from '../../components/layout';
import SEO from '../../components/SEO/seo';

const SnobFigma = () => (
    <Layout >
        <SEO lang="en" title='Designing better text editor' keywords={['design', 'UX', 'UI', 'Artyom Alekseev', 'Артём Алексеев', 'website', 'usability', 'snob']} description="Designing Snob's editor">
        </SEO>

        <HeroSection animationType="noanima" heading="Designing better text editor" desc="Making better text editor for Snob.ru"/>

        <Section heading="Preamble">
            <Paragraph>Snob, it's not only a news website but also a center of interesting discussions. There is a blog or community part of the website, where can write articles and comment not only our editors, columnists but also our subscribers. </Paragraph>
            <Paragraph>In this case, I tell about an entry point for new subscriber-made content - our editor.</Paragraph>
        </Section>

        <Section>
            <Paragraph>One day you find yourself overwhelmed. Figma loading slow, your searching for ten minutes for a screen or page that someone designed like a year ago. </Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Study" >
            <Paragraph>It's important to keep our documentation, ideas, and solutions tidy and easy to reach out to. So made a page in Notion where we keep all our ideas and resources. We also made a sitemap with features. </Paragraph>
            <Paragraph>We write down competitors or websites in which we like an editor the most. It's important to look at big websites to make our design also familiar to other editors so that as many people as possible can handle our editor.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Preview modal">
            <Paragraph>To make user-generated content look good, we need to motivate our users to add photos and create a more brief and nicer heading, we need to add constraints for them, but gently.</Paragraph>
            <Paragraph>So after you try to publish an article, you see that modal, where you can write your heading, subheading, and picture for your preview card on other parts of the website.</Paragraph>
            <Paragraph>We autoload the maximum of the data from the actual article to minimize user cognitive load. Users will see if their heading will be abrupt or trimmed which is leading them to change and improve their headings</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Mobile version">
            <Paragraph>It's important to make our designs functional even on mobile. Of course, we can't create a full word processor on the mobile web, but we should make it possible to quickly change the article on the go.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Small interactions">
            <Paragraph>Small interactions are also important, like if a user deletes preloaded text, how to preload it again? And we made a different state for that too. A small button appears and you can easily get it back.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Testing">
            <Paragraph>We made a test version for users to get to know some big problems to fix. So we get them the text version, ask them to do their usual things, and give them a questionnaire. </Paragraph>
            <Paragraph>We find out some drawbacks of our design when users tried to re-edit their articles. And of course, some bugs to fix.</Paragraph>
        </Section>

        <Section salEasing="ease-in-out-cubic" dataSal="fade" salDelay="300" heading="Constantly improving">
            <Paragraph>Then, we create another state which is needed to be improved, I always improving the management of all states then the page has to easily find a state that you need, about managing Figma files I wrote another article that is more practical for designers.</Paragraph>
        </Section>

    </Layout>
  );

export default SnobFigma;
